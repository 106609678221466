<template>
  <div>
    <section class="store-open-area-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>가맹점 오픈가능지역 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PARTNERSHIP</span>&nbsp;&gt;&nbsp;
            <span>가맹점 오픈가능지역 관리</span>
          </p>
          <ul class="tab-menu store-tab-menu">
            <li>
              <button
                v-bind:class="{ checked: selectedClassType == 0 }"
                @click="onButtonClassType(0)"
              >
                전체
              </button>
            </li>
            <li>
              <button
                v-bind:class="{ checked: selectedClassType == 1 }"
                @click="onButtonClassType(1)"
              >
                서울
              </button>
            </li>
            <li>
              <button
                v-bind:class="{ checked: selectedClassType == 2 }"
                @click="onButtonClassType(2)"
              >
                영남
              </button>
            </li>
            <li>
              <button
                v-bind:class="{ checked: selectedClassType == 3 }"
                @click="onButtonClassType(3)"
              >
                경기
              </button>
            </li>
            <li>
              <button
                v-bind:class="{ checked: selectedClassType == 4 }"
                @click="onButtonClassType(4)"
              >
                그 외
              </button>
            </li>
          </ul>
          <!-- /.tab menu-->
          <p class="total-post">
            전체 게시물 <span>{{ getDataTotalCount }}</span
            >개
          </p>
          <!-- /.total-post -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">대분류</th>
                <th scope="col">중분류</th>
                <th scope="col">소분류</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getData" v-bind:key="item.id">
                <td>
                  <router-link
                    :to="{
                      name: 'StoreOpenAreaEdit',
                      params: { id: item.id, item: item },
                    }"
                    >{{ showParentName(item.parent_id) }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'StoreOpenAreaEdit',
                      params: { id: item.id, item: item },
                    }"
                    >{{ item.name }}
                  </router-link>
                </td>

                <td class="cont-cell">
                  <router-link
                    :to="{
                      name: 'StoreOpenAreaEdit',
                      params: { id: item.id, item: item },
                    }"
                    class="left of-hidden"
                  >
                    {{
                      getDetailsNameListsStringFromArray(
                        item.store_area_details
                      )
                    }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div>
          <!--./paginate-->
          <div class="btn-wrap">
            <router-link :to="{ name: 'StoreOpenAreaCreate' }" class="btn-blue"
              >등록</router-link
            >
          </div>
          <!-- /.btn-wrap -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import Paginate from "../../Paginate.vue";
import { mapGetters } from "vuex";

export default {
  components: { AppFooter, Paginate },
  name: "StoreOpenAreaList",
  data() {
    return {
      selectedClassType: 0,
      filteredData: [],
    };
  },
  computed: {
    ...mapGetters({
      getData: "partnership/getStoreAreaList",
      getDataTotalCount: "partnership/getStoreAreaTotalCount",
    }),
  },
  created() {
    this.checkedValues = [];
    this.$store.dispatch("partnership/req_store_area_list", {
      page: 1,
      size: this.PAGE_SIZE,
    });
  },
  methods: {
    getDetailsNameListsStringFromArray(details) {
      // details 는 object array 임.
      const strName = details.map((el) => el.name).join(" / ");
      // console.log("strName => ", strName);
      return strName;
    },
    showParentName(pid) {
      if (pid == 1) return "서울";
      else if (pid == 2) return "영남";
      else if (pid == 3) return "경기";
      else if (pid == 4) return "그 외";
      else return "";
    },
    handlePageSelected(page) {
      console.log("handlePageSelected => ", page);
      this.$store.dispatch("partnership/req_store_area_list", {
        page: page,
        size: this.PAGE_SIZE,
        parent_id: this.selectedClassType,
      });
    },
    onButtonClassType(type) {
      this.selectedClassType = type;
      this.$store.dispatch("partnership/req_store_area_list", {
        page: 1,
        size: this.PAGE_SIZE,
        parent_id: type,
      });
    },

    // 선택 삭제시 해당 Row 를 추가할 방법이 없음.

    // onSubmitDelete()  {
    //   console.log("onSubmitDelete ==> ", this.checkedValues);
    //   if (this.checkedValues.length > 0) {

    //     // 서버에서 store_area_details 도 같이 삭제하여야함.
    //     this.$store.dispatch("partnership/req_remove_store_area_list", {
    //       idarr: this.checkedValues,
    //     }).then( (result) => {
    //       this.checkedValues = [];
    //       this.data_list = result.data ;
    //       this.data_total_count = result.total_count  ;
    //     });

    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/assets/scss/helpers/variables";
@import "/src/assets/scss/helpers/mixins";
@import "/src/assets/scss/base/typhography";

.store-open-area-list {
  .section-inner {
    .section-cont {
      table {
        colgroup {
          .col1 {
            width: 200px;
          }
          .col2 {
            width: 250px;
          }
        }
      }
    }
  }
}
</style>
