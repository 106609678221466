<template>
  <div>
    <section class="partnership-manager-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>제휴문의 담당자 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PRODUCTS</span>&nbsp;&gt;&nbsp;
            <span>제휴문의 담당자 관리</span>
          </p>
          <!-- /.site-path -->
          <p class="total-post">전체 게시물 <span>{{ getDataTotalCount }}</span>개</p>
          <!-- /.total-post -->

          <search-input :searchHandler="onSearch" />


          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
              <col class="col5" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" class="check-cell">
                  <label for="" class="hidden">전체체크</label
                  ><input type="checkbox" @click="check()" v-model="checkAllValue" />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="../../../assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </th>
                <th scope="col">No.</th>
                <th scope="col">담당자</th>
                <th scope="col">연락처</th>
                <th scope="col">이메일</th>
              </tr>
            </thead>
            <tbody>
              
              <tr v-for="item in getData" v-bind:key="item.id">
                <td class="check-cell">
                  <label for="" class="hidden">개설문의체크</label
                  ><input type="checkbox" v-model="checkedValues" :value="item.id"/>
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="../../../assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </td>
                <td>
                  <router-link :to="{ name: 'PartnershipManagerEdit' , params: { id: item.id, item: item } }">
                    {{item.id}}
                  </router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'PartnershipManagerEdit', params: { id: item.id, item: item }  }">
                    {{item.name}}
                  </router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'PartnershipManagerEdit', params: { id: item.id, item: item }  }">
                    {{item.mobile_no}}
                  </router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'PartnershipManagerEdit' , params: { id: item.id, item: item } }">
                    {{item.email}}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div>
          <!-- /.paginate -->
          <div class="btn-wrap">
            <button class="btn-icon-del" @click="onConfirmDelete">
              <img src="../../../assets/image/btn-del.png" alt="delete icon" />
              <span>선택삭제</span>
            </button>
            <router-link
              :to="{ name: 'PartnershipManagerCreate' }"
              class="btn-blue"
              >등록</router-link
            >
          </div>
          <!-- /.btn-wrap -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import Paginate from "../../Paginate.vue";
import SearchInput from "../../SearchInput.vue";

import mananger_handle_mixin from '../../../mixins/managerHandle_mixin'; 

export default {
  components: { AppFooter, Paginate, SearchInput },
  name: "PartnershipManagerList",
  mixins: [mananger_handle_mixin],
  data() {
    return {
      taskpart: 'partnership',
    };
  },
  computed: {

    // getData: function() {
    //   return this.manager_list; 
    // },
    // getDataTotalCount: function() {
    //   return this.manager_total_count; 
    // },

  },
  created() {
    this.loadManagerData({
            taskpart: this.taskpart ,
            page: 1, 
            size: this.PAGE_SIZE ,
          }) ;
  },
  methods: {
    onSearch(text) {
      this.searchtext = text ; 
      this.loadManagerData({
        page: 1,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext ,
        taskpart: this.taskpart,
      }) ;
    },
    handlePageSelected(page) {
      var payload = {
            taskpart: this.taskpart ,
            page: page, 
            size: this.PAGE_SIZE,
            searchtext: this.searchtext ,
      } ; 
      console.log("handlePageSelected => ", page);
      this.loadManagerData(payload) ;   
    },
    onSubmitDelete() {
      console.log("onSubmitDelete ==> ", this.checkedValues);
      if (this.checkedValues.length > 0) {
        this.removeManagerList(); 
      }
    },

  },
};
</script>
<style lang="scss" scoped>
.partnership-manager-list {
  .section-inner {
    .section-cont {
      table {
        colgroup {
          .col1 {
            width: 64px;
          }
          .col2 {
            width: 106px;
          }
        }
      }
    }
  }
}
</style>
