<template>
  <div>
    <section class="partner-manager-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>제휴문의 담당자 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PRODUCTS</span>&nbsp;&gt;&nbsp;
            <span>제휴문의 담당자 관리</span>
          </p>
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="partnership-manager-name">담당자</label>
              </th>
              <td>
                <input
                  type="text"
                  id="partnership-manager-name"
                  name="partnership-manager-name"
                  v-model="name"
                  autofocus
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="partnership-manager-num">연락처</label>
              </th>
              <td>
                <input
                  type="text"
                  id="partnership-manager-num"
                  name="partnership-manager-num"
                  v-model="mobile_no"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="partnership-manager-email">이메일</label>
              </th>
              <td>
                <input
                  type="text"
                  id="partnership-manager-email"
                  name="partnership-manager-email"
                  v-model="email"
                />
              </td>
            </tr>
          </table>
          <!-- /.table-->

          <router-link
            :to="{ name: 'PartnershipManagerList' }"
            class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitCreate">저장</button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>
 
<script>
import AppFooter from "../../AppFooter.vue";
import mananger_handle_mixin from '../../../mixins/managerHandle_mixin'; 

export default {
  components: { AppFooter },
  name: "PartnershipManagerCreate",
  mixins: [mananger_handle_mixin],
  data() {
    return {
      taskpart: 'partnership',
    }
  },
  methods: {
    onSubmitCreate() { 
      console.log('onSubmitCreate ==> ', ); 
      
      this.reqCreateManager( {
          taskpart: this.taskpart,
          name: this.name,
          mobile_no: this.mobile_no,
          email: this.email ,
        } , "PartnershipManagerList" ) ; 
    }
  },

};
</script>
