<template>
  <div>
    <section class="store-open-inquire-manager-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>가맹 개설문의 담당자 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PARTNERSHIP</span>&nbsp;&gt;&nbsp;
            <span>가맹 개설문의 담당자 관리</span>
          </p>
          <!-- /.site-path -->
          <p class="total-post">
            전체 게시물 <span> {{ getDataTotalCount }} </span>개
          </p>
          <!-- /.total-post -->
          <search-input :searchHandler="onSearch" />
          <!-- /.search -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
              <col class="col5" />
              <col class="col6" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" class="check-cell">
                  <label for="" class="hidden">전체체크</label
                  ><input
                    type="checkbox"
                    @click="check()"
                    v-model="checkAllValue"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="../../../assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </th>
                <th scope="col">No.</th>
                <th scope="col">담당자</th>
                <th scope="col">연락처</th>
                <th scope="col">이메일</th>
                <th scope="col">담당지역</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getData" v-bind:key="item.id">
                <td class="check-cell">
                  <label for="" class="hidden">개설문의체크</label
                  ><input
                    type="checkbox"
                    v-model="checkedValues"
                    :value="item.id"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="../../../assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'StoreOpenInquireManagerEdit',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.id }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'StoreOpenInquireManagerEdit',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.name }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'StoreOpenInquireManagerEdit',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.mobile_no }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'StoreOpenInquireManagerEdit',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.email }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'StoreOpenInquireManagerEdit',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.details }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div>
          <!--./paginate-->
          <div class="btn-wrap">
            <button class="btn-icon-del" @click="onConfirmDelete">
              <img src="../../../assets/image/btn-del.png" alt="delete icon" />
              <span>선택삭제</span>
            </button>
            <router-link
              :to="{ name: 'StoreOpenInquireManagerCreate' }"
              class="btn-blue"
              >등록</router-link
            >
          </div>
          <!-- /.btn-wrap -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import Paginate from "../../Paginate.vue";
import SearchInput from "../../SearchInput.vue";

export default {
  components: { AppFooter, Paginate, SearchInput },
  name: "StoreOpenInquireManagerList",
  data() {
    return {
      taskpart: "open",
      manager_list: [],
      manager_total_count: 0,
    };
  },
  computed: {
    getData: function () {
      return this.manager_list;
    },
    getDataTotalCount: function () {
      return this.manager_total_count;
    },
  },
  created() {
    this.$store
      .dispatch("req_manager_list", {
        taskpart: this.taskpart,
        page: 1,
        size: this.PAGE_SIZE,
      })
      .then((result) => {
        console.log("list result => ", result);
        this.manager_list = result.data;
        this.manager_total_count = result.total_count;
      })
      .catch((err) => {
        console.log(" error => ", err);
      });
  }, 
  methods: {
    loadData( payload ) {
      this.$store
        .dispatch("req_manager_list", payload )
        .then((result) => {
          console.log("list result => ", result);
          this.manager_list = result.data;
          this.manager_total_count = result.total_count;
        })
        .catch((err) => {
          console.log(" error => ", err);
        }); 
    },
    onSearch(text) {
      this.searchtext = text ; 
      this.loadData({
        page: 1,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext ,
        taskpart: this.taskpart,
      })
    },
    handlePageSelected(page) {
      console.log("handlePageSelected => ", page);
      this.loadData({
        page: page,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext ,
        taskpart: this.taskpart,
      })
    },
    onSubmitDelete() {
      console.log("onSubmitDelete ==> ", this.checkedValues);
      if (this.checkedValues.length > 0) {
        // choice
        this.$store
          .dispatch("req_remove_manager_list", {
            idarr: this.checkedValues,
            taskpart: this.taskpart,
          })
          .then((result) => {
            this.checkedValues = [];
            this.manager_list = result.data;
            this.manager_total_count = result.total_count;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.store-open-inquire-manager-list {
  .section-inner {
    .section-cont {
      table {
        colgroup {
          .col1 {
            width: 64px;
          }
          .col2 {
            width: 90px;
          }
          .col3 {
            width: 130px;
          }
          .col6 {
            width: 190px;
          }
        }
      }
    }
  }
}
</style>
