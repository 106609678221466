<template>
  <div>
    <section class="partnership-post-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>제휴문의</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PRODUCTS</span>&nbsp;&gt;&nbsp;
            <span>제휴문의</span>
          </p>
          <p class="total-post">
            전체 게시물 <span>{{ getDataTotalCount }}</span
            >개
          </p>
          <!-- /.total-post -->
          <search-input :searchHandler="onSearch" />
          <!-- /.search -->

          <label for="partership-category"
            ><span class="hidden">제휴유형</span></label
          >
          <!------------------제휴 옵션 셀렉트박스 추가 ------------------>
          <select
            name="partership-category"
            id="partership-category"
            @change="onChangeType($event)"
            v-model="selectedType"
          >
            <option value="null">전체</option>
            <option
              v-for="item in this.partnerTypeList"
              v-bind:key="item.id"
              :value="item"
            >
              {{ item.name }}
            </option>
          </select>
          <!------------------제휴 옵션 셀렉트박스 추가 end ------------------>
          <!-- /.select -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
              <col class="col5" />
              <col class="col6" />
              <col class="col7" />
              <col class="col8" />
              <col class="col9" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">제휴 유형</th>
                <th scope="col">제목</th>
                <th scope="col">회사(기관)명</th>
                <th scope="col">제안자 명</th>
                <th scope="col">등록일시</th>
                <th scope="col">확인일시</th>
                <th scope="col">확인 담당자</th>
                <th scope="col">회신</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in getData" v-bind:key="item.id">
                <td>
                  <router-link
                    :to="{
                      name: 'PartnershipPostShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.id }}
                  </router-link>
                </td>
                <td>
                  <!------------------제휴유형 표기------------------>
                  <router-link
                    :to="{
                      name: 'PartnershipPostShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.type_name }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'PartnershipPostShow',
                      params: { id: item.id, item: item },
                    }"
                    class="left of-hidden"
                    >{{ item.title }}</router-link
                  >
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'PartnershipPostShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.company_name }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'PartnershipPostShow',
                      params: { id: item.id, item: item },
                    }"
                    >{{ maskingFuncName(item.name) }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'PartnershipPostShow',
                      params: { id: item.id, item: item },
                    }"
                    class="of-hidden"
                  >
                    {{ $moment(item.created_dttm).format("YYYY.MM.DD HH:mm") }}
                  </router-link>
                </td>
                <td>
                  <!------------------확인일시------------------>
                  <router-link
                    :to="{
                      name: 'PartnershipPostShow',
                      params: { id: item.id, item: item },
                    }"
                    class="of-hidden"
                  >
                    {{
                      item.confirm_dttm != null
                        ? $moment(item.confirm_dttm).format("YYYY.MM.DD HH:mm")
                        : "-"
                    }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'PartnershipPostShow',
                      params: { id: item.id, item: item },
                    }"
                    class="of-hidden"
                  >
                    {{
                      item.confirm_manager != null ? item.confirm_manager : "-"
                    }}
                  </router-link>
                </td>
                <td>
                  <!------------------회신 유------------------>
                  <a
                    href=""
                    v-if="
                      item.partnership_inquire_posts_answers != null &&
                      item.partnership_inquire_posts_answers.length > 0
                    "
                    >O</a
                  >
                  <!------------------회신 무------------------>
                  <a href="" v-else>X</a>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- /.table-->
          <div class="page">
            <paginate
              :initPage="selectedPage"
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
              :key="selectedPage"
            ></paginate>
          </div>
          <!--./paginate-->
          <div class="btn-wrap">
            <!-- <button class="btn-icon-del" @click="onConfirmDelete">
              <img src="../../../assets/image/btn-del.png" alt="delete icon" />
              <span>선택삭제</span>
            </button> -->
            <router-link
              :to="{ name: 'PartnershipTypeCreate' }"
              class="btn-blue"
              >제휴 유형 설정</router-link
            >
          </div>
          <!-- /.btn-wrap -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import Paginate from "../../Paginate.vue";
import { mapGetters, mapMutations } from "vuex";
import SearchInput from "../../SearchInput.vue";

export default {
  components: { AppFooter, Paginate, SearchInput },
  name: "PartnershipPostList",
  data() {
    return {
      selectedPage: 1,
      selectedType: null,
      confirm: true, // 임시로 넣은 데이터 삭제 필요
      partnerTypeList: [],
      bOnChangeTypeGoSelectedPage: false,
    };
  },
  computed: {
    ...mapGetters({
      getPartnershipPostSelectedType:
        "partnership/getPartnershipPostSelectedType",
      getSelectedPage: "partnership/getPartnershipPostSelectedPage",
      getData: "partnership/getPartnershipPostList",
      getDataTotalCount: "partnership/getPartnershipTotalCount",
      getTypeList: "partnership/getPartnershipTypeList",
    }),
  },
  async created() {
    console.log("created");
    await this.$store.dispatch("partnership/req_partnership_type_list", {});
    // .then(() => {
    //   this.partnerTypeList = [...this.getTypeList];
    //   this.partnerTypeList.sort((a, b) => {
    //     return a.order - b.order;
    //   });

    //   this.selectedPage = this.getSelectedPage;
    //   this.selectedType = this.getPartnershipPostSelectedType;
    //   console.log("created page : ", this.selectedPage);
    // });

    this.partnerTypeList = [...this.getTypeList];
    this.partnerTypeList.sort((a, b) => {
      return a.order - b.order;
    });

    this.bOnChangeTypeGoSelectedPage = true;
    this.selectedPage = this.getSelectedPage;
    // this.selectedType = this.getPartnershipPostSelectedType; // 타입 설정으로 인해 onChangeType 실행되는경우가 있고 안되는경우가 있는디.. 흠..
    this.$store
      .dispatch("partnership/req_partnership_post_list", {
        page: this.selectedPage,
        size: this.PAGE_SIZE,
        type_id:
          this.getPartnershipPostSelectedType != null
            ? this.getPartnershipPostSelectedType.id
            : null,
      })
      .then(() => {
        this.selectedType = this.getPartnershipPostSelectedType;
        console.log("created. load data.");
      });
  },
  methods: {
    ...mapMutations({
      setPartnershipPostPage: "partnership/SET_PARTNERSHIP_POST_PAGE",
      setPartnershipPostSelectedType:
        "partnership/SET_PARTNERSHIP_POST_SELECTED_TYPE",
    }),
    onChangeType(event) {
      console.log("onChangeType ==> ", event.target.value);
      const prevType = this.getPartnershipPostSelectedType;
      console.log(
        "onChangeType prev : ",
        prevType,
        " => : ",
        this.selectedType
      );

      // 타입이 변경되면 무건 1번 페지이지를 로딩하여야함.

      // 타입 정보 변경
      this.setPartnershipPostSelectedType({
        partnership_post_selected_type: this.selectedType,
      });
      console.log(
        "onChangeType this.bOnChangeTypeGoSelectedPage ==> ",
        this.bOnChangeTypeGoSelectedPage
      );

      // let goPage = this.bOnChangeTypeGoSelectedPage ? this.selectedPage : 1;
      let goPage = 1;

      this.selectedPage = goPage;
      this.bOnChangeTypeGoSelectedPage = false;
      this.loadData({
        page: goPage,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
        type_id: this.selectedType != null ? this.selectedType.id : null,
      });
    },
    loadData(payload) {
      this.$store
        .dispatch("partnership/req_partnership_post_list", payload)
        .then(() => {
          // state에는 이미저장한상태임.
          this.selectedPage = payload.page;
        });
    },
    onSearch(text) {
      this.searchtext = text;
      this.loadData({
        page: 1,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
        type_id: this.selectedType != null ? this.selectedType.id : null,
      });
    },
    handlePageSelected(page) {
      // console.log("handlePageSelected => ", page);
      this.loadData({
        page: page,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
        type_id: this.selectedType != null ? this.selectedType.id : null,
      });
    },
    onSubmitDelete() {
      console.log("onSubmitDelete ==> ", this.checkedValues);
      if (this.checkedValues.length > 0) {
        // choice
        this.$store
          .dispatch("partnership/remove_partnership_post_list", {
            idarr: this.checkedValues,
          })
          .then(() => {
            this.checkedValues = [];
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.partnership-post-list {
  .section-inner {
    .section-cont {
      table {
        colgroup {
          .col1 {
            width: 80px;
          }
          .col2 {
            width: 120px;
          }
          .col4 {
            width: 140px;
          }
          .col5 {
            width: 120px;
          }
          .col8 {
            width: 130px;
          }
          .col9 {
            width: 70px;
          }
        }
      }
      .btn-blue {
        width: 140px;
      }
      select[name="partership-category"] {
        position: absolute;
        top: 90px;
        width: 250px;
        height: 50px;
        padding: 11px 20px;
        border: 1px solid #ccc;
        right: 40px;
      }
      .search {
        right: 56%;
        transform: translateX(50%);
      }
    }
  }
}
</style>
