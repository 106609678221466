<template>
  <div>
    <section class="store-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>가맹점 현황 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PARTNERSHIP</span>&nbsp;&gt;&nbsp;
            <span>가맹점 현황 관리</span>
          </p>
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            
            <tr>
              <th scope="row">
                <label for="store-year">년</label>
              </th>
              <td>
                <select
                  name="store-year"
                  id="store-year"
                  v-model="selectedYear" 
                  @change="onChangeYear()"
                >
                  <option
                    v-for="item in this.getSelectOptionYears"
                    v-bind:key="item"
                    :value="item"
                  >
                    {{ item }} 
                  </option>
                </select>
              </td>
            </tr>


            <tr>
              <th scope="row">
                <label for="store-month">월</label>
              </th>
              <td>
                <select name="store-month" id="store-month" @change="onChangeMonth()" v-model="selectedMonth">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="store-date">일</label>
              </th>
              <td>
                <select name="store-date" id="store-date" @change="onChangeDay()" v-model="selectedDay">
                  <option
                    v-for="item in this.daysInYearMonth"
                    v-bind:key="item"
                    :value="item"
                  >
                    {{ item }} 
                  </option> 
                </select>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="store-name">가맹점 명</label>
              </th>
              <td>
                <input type="text" id="store-name" name="store-name" v-model="store_name"/>
              </td>
            </tr>
            <tr>
              <th scope="row">신규 오픈</th>
              <td class="radio-cell">
                <span>
                  <input
                    type="radio"
                    id="store-use"
                    name="store"
                    value=1
                    v-model="isNewOpen"
                    checked
                  />
                  <label for="store-use">사용</label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="store-unuse"
                    name="store"
                    value=0
                    v-model="isNewOpen"
                  />
                  <label for="store-unuse">미사용</label>
                </span>
              </td>
            </tr>
          </table>
          <router-link :to="{ name: 'StoreList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickSave">저장</button>
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

export default {
  components: { AppFooter },
  name: "StoreCreate",
  data() {
    return {
      isNewOpen: 1,
      store_name: '', 
      selectedYear: this.item != null ? this.item.fullyear : new Date().getFullYear() ,
      selectedMonth: 1,
      selectedDay:1,
      daysInYearMonth: [],
    } 
  },
  computed: {
 
  },
  created() {
      this.daysInYearMonth = this.$_.range( 1, new Date(this.selectedYear, this.selectedMonth, 0).getDate() + 1 ); 
      // 가맹점 현황 데이터 요청. 
  },
  methods: {
    onChangeYear() {
      this.selectedMonth = 1; 
      this.selectedDay = 1 ; 
      this.daysInYearMonth = this.$_.range( 1, new Date(this.selectedYear, this.selectedMonth, 0).getDate() + 1 ); 

    } ,
    onChangeMonth() {
      this.daysInYearMonth = this.$_.range( 1, new Date(this.selectedYear, this.selectedMonth, 0).getDate() + 1 ); 
      if( !this.daysInYearMonth.includes(this.selectedDay) ) this.selectedDay = 1 ; 
    } ,
    onChangeDay() {
      console.log('onChangeDay ==> ', this.selectedYear , this.selectedMonth , this.selectedDay ) ;
    } ,
    onClickSave() {
      console.log('onClickSave') ;


      const strYear = this.selectedYear ; 
      const strMonth = this.selectedMonth >= 10 ? this.selectedMonth : '0' + this.selectedMonth;
      const strDay = this.selectedDay >= 10 ? this.selectedDay : '0' + this.selectedDay;

      var fullyear =  strYear + '.' + strMonth +'.' + strDay ; 

      // request 
      this.$store.dispatch("partnership/req_create_store_open_condition", { 
        name: this.store_name ,
        is_newopened: this.isNewOpen,
        open_fullyear: fullyear,
      }).then( () => {
        this.$store.dispatch("partnership/req_store_open_condition_list", { 
          page: 1,
          size: this.PAGE_SIZE,
        });
          this.$fire({
            title: this.msg.create_success_title,
            text: "", 
            type: "success",
            timer: 2000
          }).then(r => {
            console.log(r.value);
            this.$router.push({ name: "StoreList" });
          });
      });
 

    }

  }

};
</script>
