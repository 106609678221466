<template>
  <div>
    <section class="store-open-area-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>가맹점 오픈가능지역 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PARTNERSHIP</span>&nbsp;&gt;&nbsp;
            <span>가맹점 오픈가능지역 관리</span>
          </p>
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="store-division1">대분류</label>
              </th>
              <td>
                <select name="store-division1" id="store-division1"  @change="onChangeDivision1()" v-model="division1">
                  <option value="선택">선택</option>
                  <option value="서울">서울</option>
                  <option value="영남">영남</option>
                  <option value="경기">경기</option>
                  <option value="그 외">그 외</option>
                </select>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="store-division2">중분류</label>
              </th>
              <td>
                <select name="store-division2" id="store-division2" @change="onChangeDivision2()" v-model="division2">
                  <option
                    v-for="item in this.division2List"
                    v-bind:key="item.id"
                    :value="item"
                  >
                    {{ item.name }} 
                  </option>

                </select>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="store-division3">소분류 등록</label>
              </th>
              <td class="register-cell">
                <input
                  type="text"
                  id="store-division3"
                  name="store-division3"
                  v-model="inputDetails"
                  placeholder="추가입력"
                />
                <input type="submit" @click="onSubmitDetails" />
              </td>
            </tr>
            <tr>
              <th>소분류</th>
              <td class="store-division3-wrap">
                <p class="store-division3-empty hidden">
                  등록된 분류가 없습니다.
                </p>

                <p
                  v-for="item in this.arealist"
                  v-bind:key="item.name"
                  class="store-division3-cont"
                >
                  {{ item.name }}
                  <button @click="onClickRemoveItem(item)">
                    <img
                      src="../../../assets/image/btn-del-b.png"
                      alt="search icon"
                    />
                  </button>
                  <span></span>
                </p>

              </td>
            </tr>
          </table>
          <!-- /.table-->

          <router-link :to="{ name: 'StoreOpenAreaList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onUpdateRequest">수정</button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

export default {
  props: ["id", "item"],
  components: { AppFooter },
  name: "StoreOpenAreaEdit",
  data() {
    return {
      division1: "서울",
      division2: null ,
      division2List: [], 
      inputDetails: "",
      initial_arealist: [], 
      arealist: [ ],
      removearealist : [],
      addarealist:[],
    };
  },
  computed: {

    getAreaCodeList: function() {
      return this.$store.getters['partnership/getStoreAreaCodeList']; 
    },
    getDivision1: function() {
      var codelist = this.$store.getters['partnership/getStoreAreaCodeDivision1List']
      return codelist; 
    },

  },
  async created() {
    console.log('StoreOpenAreaEdit created. => id , item ' , this.id , this.item );
    await this.$store.dispatch("partnership/req_store_area_code_list"); 

    this.initial_arealist = this.item.store_area_details.map( function (el) {
      return { name: el.name, area_id : el.area_id } ; 
    } );
    this.arealist = [...this.initial_arealist]; 

    const parent_id = this.item.parent_id;
    if( parent_id == 1 )      { this.division1 = "서울" ; }
    else if( parent_id == 2 ) { this.division1 = "영남" ; }
    else if( parent_id == 3 ) { this.division1 = "경기" ; }
    else if( parent_id == 4 ) { this.division1 = "그 외" ; } 

    this.division2List = this.getAreaCodeList.filter( el => el.parent_id == this.item.parent_id && el.class_type == 2 ) ; 
    const findDivision = this.division2List .find((e) => e.name == this.item.name);
    this.division2 = findDivision ; 
    
    console.log('StoreOpenAreaEdit created. => division2List ' , this.division2List , this.division2 );


  },
  methods: {
    onClickItem() {
      console.log("item clicked");
    },
    onSubmitDetails() {

      if ( this.inputDetails.length > 0 ) {
        if( this.arealist.find( el => el.name == this.inputDetails ) == null ) {
          this.arealist.push( { name: this.inputDetails, area_id : this.division2.id } ) ;       
          console.log("onSubmitDetails add item ==>> " , this.inputDetails );
          this.inputDetails = "";
        }
        else {
          console.log("onSubmitDetails item ==>> " , this.inputDetails , " 이미 존재");
        }
        // this.arealist = this.arealist.filter(item => item.name != this.inputDetails)
      }

      this.inputDetails = "";

    },
    onChangeDivision1() {

      console.log('onChangeDivision1 ==> ', this.division1 ) ;
      // var division1list = this.getDivision1 ;
      let division_code ; 
      if( "서울" == this.division1 )      { division_code = 1 ;  }
      else if( "영남" == this.division1 ) { division_code = 2 ;  }
      else if( "경기" == this.division1 ) { division_code = 3 ;  }
      else if( "그외" == this.division1.replace(/ /gi, "") ) {division_code = 4 ; } 

      console.log( ' this.getAreaCodeList ', this.getAreaCodeList , division_code  ); 
      this.division2List = this.getAreaCodeList.filter( el => el.parent_id == division_code && el.class_type == 2 ) ; 
      console.log( ' this.division2 ==>  ',this.division2List ); 
      this.division2 = this.division2List[0]; 

    },
    onClickRemoveItem(item) {
      const findDivision = this.initial_arealist .find((e) => e.name == this.item.name);
      if( findDivision != null ) console.log("onRemoveItem => initial_arealist 에서 item  ", item , ' 삭제.') ;

      this.arealist = this.arealist.filter((element) => element != item);
    },

    onChangeDivision2() {
      console.log('onChangeDivision2 ==> ', this.division2);
      
    },
    onUpdateRequest() {
      console.log("onUpdateRequest =>");

      let addedlist = this.arealist.filter( el => !this.initial_arealist .includes(el));
      let deletedlist = this.initial_arealist.filter( el => !this.arealist .includes(el));

      // null 이어도 수정이므로 전제 삭제
      if( (addedlist.length + deletedlist.length ) > 0 )
      {
        console.log("onUpdateRequest => addedlist " , addedlist  );
        console.log("onUpdateRequest => deledlist " , deletedlist  );

        // 등록/삭제 요청 request 
        this.$store.dispatch("partnership/req_update_store_area_detailslist" , { addedlist: addedlist , deletedlist: deletedlist }).then( () => {
          console.log( 'update completed.'); 
            this.$fire({
            title: this.msg.edit_success_title,
            text: "", 
            type: "success",
            timer: 2000
          }).then(r => {
            console.log(r.value);
            this.$router.push({ name: "StoreOpenAreaList" });
          });
        }) ;
      }
    }
  },
};
</script>
