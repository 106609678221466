<template>
  <div>
    <section class="store-open-inquire-manager-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>가맹 개설문의 담당자 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PARTNERSHIP</span>&nbsp;&gt;&nbsp;
            <span>가맹 개설문의 담당자 관리</span>
          </p>
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="store-manager-name">담당자</label>
              </th>
              <td>
                <input
                  type="text"
                  id="store-manager-name"
                  name="store-manager-name"
                  value="담당자 명이 표시됩니다"
                  v-model="name"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="store-manager-num">연락처</label>
              </th>
              <td>
                <input
                  type="text"
                  id="store-manager-num"
                  name="store-manager-num"
                  value="연락처가 표시됩니다"
                  v-model="mobile_no"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="store-manager-email">이메일</label>
              </th>
              <td>
                <input
                  type="text"
                  id="store-manager-email"
                  name="store-manager-email"
                  value="이메일이 표시됩니다"
                  v-model="email"

                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="store-manager-location">담당지역</label>
              </th>
              <td>
                <input
                  type="text"
                  id="store-manager-location"
                  name="store-manager-location"
                  value="지역이 표시됩니다"
                  v-model="details"
                />
              </td>
            </tr>
          </table>
          <!-- /.table-->

          <router-link
            :to="{ name: 'StoreOpenInquireManagerList' }"
            class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitModify">수정</button>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

export default {
  props: ["id", "item"],
  components: { AppFooter },
  name: "StoreOpenInquireManagerEdit",
  data() {
    return {
      taskpart: 'open',
      name: "",
      mobile_no: "",
      email: "",
      details: ""
    } 
  },
  created() {
    console.log('StoreOpenInquireManagerEdit created => ', this.id , this.item );
    this.name = this.item.name ; 
    this.mobile_no = this.item.mobile_no ; 
    this.email = this.item.email ; 
    this.details = this.item.details ; 
  },
  methods: {
    onSubmitModify() {
      this.$store
        .dispatch("req_update_manager", {
          taskpart: this.taskpart,
          id: this.id,
          name: this.name,
          mobile_no: this.mobile_no,
          email: this.email ,
          details: this.details
        })
        .then(( ) => {
          this.$alert("수정하였습니다.").then( () => {
            this.$router.push({ name: "StoreOpenInquireManagerList" });
          });
        })
        .catch((error) => {
          console.log("error => ", error);
        });
    },
  },

};
</script>
