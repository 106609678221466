<template>
  <div>
    <section class="partnership-post-show ly-show">
      <div class="section-inner">
        <div class="section-cont">
          <article class="partnership-post-show__inquiry">
            <h2>제휴문의</h2>
            <p class="site-path">
              <span>Home</span>&nbsp;&gt;&nbsp;
              <span>PRODUCTS</span>&nbsp;&gt;&nbsp;
              <span>제휴문의</span>
            </p>
            <table>
              <colgroup>
                <col class="col1" />
                <col class="col2" />
              </colgroup>
              <tr>
                <th scope="row">제휴 유형</th>
                <td>{{ this.itemdata.type_name }}</td>
              </tr>
              <tr>
                <th scope="row">회사(기관)명</th>
                <td>{{ this.itemdata.company_name }}</td>
              </tr>
              <tr>
                <th scope="row">제안자명</th>
                <td>{{ this.itemdata.name }}</td>
              </tr>
              <tr>
                <th scope="row">이메일</th>
                <td>{{ this.itemdata.email }}</td>
              </tr>
              <tr>
                <th scope="row">연락처</th>
                <td>{{ this.itemdata.mobile_no }}</td>
              </tr>
              <tr>
                <th scope="row">제목</th>
                <td>{{ this.itemdata.title }}</td>
              </tr>
              <tr>
                <th scope="row">문의 내용</th>
                <td>
                  <p v-html="strReplaceReturnText(this.itemdata.questions)" />
                </td>
              </tr>
              <tr>
                <th scope="row">첨부파일</th>
                <td>
                  <!-- <a href="" class="file-download">제안서.pdf</a> [997.75MB] -->
                  <a
                    v-if="this.itemdata.attachment != null"
                    :href="this.itemdata.attachment.download_url"
                    :download="this.itemdata.attachment.originalname"
                    class="resume-download"
                    target="_blank"
                    >{{ this.itemdata.attachment.originalname }}
                  </a>
                  &nbsp;&nbsp;<span v-if="this.itemdata.attachment != null"
                    >[{{
                      formatSizeUnits(this.itemdata.attachment.size)
                    }}]</span
                  >
                  <span v-else>-</span>
                </td>
              </tr>
              <tr>
                <th scope="row">확인 담당자</th>
                <td>
                  <span>{{
                    this.itemdata.confirm_manager != null &&
                    this.itemdata.confirm_manager.length > 0
                      ? this.itemdata.confirm_manager
                      : "-"
                  }}</span>
                </td>
              </tr>
              <tr>
                <th scope="row">확인일시</th>
                <td>
                  <span>{{
                    this.itemdata.confirm_dttm != null
                      ? $moment(this.itemdata.confirm_dttm).format(
                          "YYYY.MM.DD HH:mm"
                        )
                      : "-"
                  }}</span>
                </td>
              </tr>
            </table>
            <!--/.table-->
            <div class="space-between">
              <router-link
                :to="{ name: 'PartnershipPostList' }"
                class="btn-white"
                >목록</router-link
              >
              <!------------------------확인------------------>
              <div class="manager" v-if="!existConfirmManager">
                <label for="manager">확인 담당자명<span>*</span></label>
                <input type="text" id="manager" v-model="confirm_manager" />
                <button class="btn-white" @click="onConfirmConfirmManager">
                  확인
                </button>
                <!------------------------확인------------------>
              </div>
              <!------------------------미확인------------------>
              <button v-else class="btn-blue" @click="onUnConfirm">
                미확인
              </button>
              <!------------------------미확인------------------>
            </div>
          </article>
          <!--partnership inquiry-->
          <!--/.btn-->
          <!-- <button
            class="btn-icon-del btn-icon-del-s btn-right"
            @click="onConfirmDelete"
          >
            <img src="../../../assets/image/btn-del.png" alt="delete icon" />
            <span>삭제</span>
          </button> -->
          <article class="partnership-post-show__email">
            <h2>제휴문의 회신 등록(이메일 발송)</h2>
            <table>
              <colgroup>
                <col class="col1" />
                <col class="col2" />
              </colgroup>
              <tr>
                <th scope="row">회신 내용</th>
                <td>
                  <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                    class="text-area"
                  >
                  </ckeditor>
                </td>
              </tr>
            </table>
            <div class="space-between">
              <div class="attached-img-wrap">
                <div class="attached-file">
                  <p v-if="file">{{ file.name }}</p>
                  <p v-else class="file-empty"></p>
                  <file-select
                    v-model="file"
                    :spanText="'이미지 첨부'"
                    v-on:input="onChangedSignatureImage"
                  ></file-select>

                  <!-- <a href="" class="file-download"> 전자서명V1_20220825.jpg</a> -->
                  <a
                    v-if="currentSignatureImagefile != null"
                    :href="this.currentSignatureImagefile.download_url"
                    :download="this.currentSignatureImagefile.download_url"
                    class="resume-download"
                    target="_blank"
                  >
                    {{ this.currentSignatureImagefile.originalname }}</a
                  >
                </div>

                <div class="file-txt">
                  ※ 가로 최대 700px의 jpg 또는 png 파일을 업로드해주세요.
                </div>
              </div>
              <button class="btn-blue" @click="onPartnershipPostAnsewer">
                회신 등록
              </button>
            </div>
          </article>
          <!--partnership email-->
          <article class="partnership-post-show__history" v-if="showAnswer">
            <h2>제휴문의 회신 히스토리</h2>
            <table>
              <colgroup>
                <col class="col1" />
                <col class="col2" />
                <col class="col3" />
              </colgroup>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>회신 내용</th>
                  <th>등록일시</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in this.answerDataList" v-bind:key="item.id">
                  <td>{{ item.id }}</td>
                  <td class="left">
                    <p v-html="item.answer" />
                  </td>
                  <td>
                    {{ $moment(item.created_dttm).format("YYYY.MM.DD HH:mm") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </article>
          <!--partnership history-->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FileSelect from "../../FileSelect.vue";
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "@/api/axios-auth";

let DEFAULT_MSG =
  "안녕하십니까. 다비치안경체인 입니다.<br> 저희 다비치안경체인에 관심을 갖고 좋은 제안을 해주셔서 감사 드립니다.<br> 접수해주신 제휴 내용은 긍정적으로 검토하여 연락 드릴 수 있도록 하겠습니다.<br><br> 추가적으로 궁금하신 내용이나, 추가 접수 내용이 있을 시 당사 홈페이지 다시 한번 접수 해주시면 확인할 수 있도록 하겠습니다.<br> 다시 한번 저희에게 관심을 갖고 좋은 제안 주셔서 정말 감사드리며 귀사의 무궁한 발전을 기원 합니다.<br><br> 감사합니다.";
export default {
  props: ["id", "item"],
  components: { AppFooter, FileSelect },
  name: "PartnershipPostShow",
  data() {
    return {
      file: null,
      currentSignatureImagefile: null,
      editor: ClassicEditor,
      editorData: DEFAULT_MSG,
      // editorDataDefault: DEFAULT_MSG,
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },
      confirm: true,
      confirm_manager: "",
      answerDataList: [],
      itemdata: {},
    };
  },
  created() {
    console.log(
      "PartnershipPostShow created ====> ",
      this.id,
      " item => ",
      this.item
    );
    this.itemdata = this.item;
    this.answerDataList = [...this.item.partnership_inquire_posts_answers];
    this.answerDataList.sort((a, b) => {
      if (a.id > b.id) return -1;
      if (a.id < b.id) return 1;
      return 0;
    });

    // 시그니처 이미지 파일 정보요청
    this.$store
      .dispatch("req_signatureimage", {})
      .then((result) => {
        console.log("result =>", result.res.data);
        this.currentSignatureImagefile = result.res.data;
        this.file = {
          name: result.res.data.originalname,
        };
      })
      .catch((error) => {
        console.log("error =>", error.response);
        if (error.response.status == 404) {
          this.file = null;
        }
      });
  },
  computed: {
    ...mapGetters("auth", ["getAdminId"]),
    // showAnswer: function () {
    //   if (
    //     this.item.partnership_inquire_posts_answers != null &&
    //     this.item.partnership_inquire_posts_answers.length > 0
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    showAnswer: function () {
      if (this.answerDataList != null && this.answerDataList.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    existConfirmManager: function () {
      return (
        this.itemdata.confirm_manager != null &&
        this.itemdata.confirm_manager.length > 0
      );
    },
  },
  methods: {
    onChangedSignatureImage(val) {
      console.log("onChangedSignatureImage ==> ", val);
      console.log("onChangedSignatureImage file ==> ", this.file);

      // 이때 파일 업로드를 해버린다.
      if (val != null) {
        let formData = new FormData();
        formData.append("title", "시그니처 이미지 파일");
        formData.append("signatureimage", this.file);

        axios
          .post("/admins/signatureimage", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log("res =>", res);
            this.$alert("업로드 성공").then(() => {
              this.currentSignatureImagefile = res.data;
            });
          })
          .catch((error) => {
            console.log("error => ", error.response.status); // status 401 인경우 비로그인 상태임.
            // console.log("error => ", error);
          });
      } else {
        // val 이 null 이면 삭제한다.
        // 삭제 요청 api  호출 필요
        axios
          .delete("/admins/signatureimage/1")
          .then((res) => {
            console.log("res =>", res);
            this.currentSignatureImagefile = res.data;
            this.$alert("삭제 성공").then(() => {});
          })
          .catch((error) => {
            console.log("error => ", error.response.status); // status 401 인경우 비로그인 상태임.
            // console.log("error => ", error);
            this.currentSignatureImagefile = null;
          });
      }
    },
    strReplaceReturnText(text) {
      let replacedText = text.replace(/(?:\r\n|\r|\n)/g, "<br />");
      return replacedText;
    },
    onUnConfirm() {
      // 미확인 상태로 되돌린다.
      const payload = {
        id: this.itemdata.id,
        confirm_manager: "",
      };
      this.$store
        .dispatch("partnership/req_update_partnership_post", payload)
        .then((result) => {
          console.log("done req_update_partnership_post => ", result);
          this.$alert("수정하였습니다.").then(() => {});
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.itemdata = result.data;
        })
        .catch((error) => {
          console.log("error => ", error);
        });
    },
    onConfirmConfirmManager() {
      if (this.confirm_manager == null || this.confirm_manager.length <= 0) {
        return this.$fire({
          title: "확인 담당자명을 입력해주세요.",
          text: "",
          type: "warning",
          timer: 2000,
        }).then((r) => {
          console.log(r.value);
        });
      }

      // 확인 담당자명 업데이트
      const payload = {
        id: this.itemdata.id,
        confirm_manager: this.confirm_manager,
        confirm_dttm: new Date(),
      };
      this.$store
        .dispatch("partnership/req_update_partnership_post", payload)
        .then((result) => {
          this.confirm_manager = "";
          console.log("done req_update_partnership_post", result);
          this.$alert("수정하였습니다.").then(() => {});
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.itemdata = result.data;
        })
        .catch((error) => {
          this.confirm_manager = "";
          console.log("error => ", error);
        });
    },
    onPartnershipPostAnsewer() {
      if (this.editorData == null || this.editorData.length <= 0) {
        //
        return this.$alert("답변 내용을 입력해 주세요").then(() => {});
      }

      this.$fire({
        title: "회신내용을 등록하시겠습니까?",
        text: "회신내용등록시 고객에게 이메일이 발송되며, 수정 및 삭제가 불가합니다.",
        type: null,
        showCancelButton: true,
        confirmButtonText: "등록",
        cancelButtonText: "취소",
      })
        .then((result) => {
          if (result.value) {
            // 등록 요청 희망
            const answerObj = {
              partnership_inquire_post_id: this.itemdata.id,
              partnership_inquire_email: this.itemdata.email,
              partnership_inquire_name: this.itemdata.name,
              partnership_inquire_question: this.itemdata.questions,
              respondent_id: this.getAdminId,
              answer: this.editorData,
            };

            // rest api call
            this.$store
              .dispatch(
                "partnership/req_create_partnership_inquire_answer",
                answerObj
              )
              .then(() => {
                // 답변 text delete
                this.$alert("답변 등록이 완료되었습니다.").then(() => {
                  this.editorData = DEFAULT_MSG;
                });

                this.$store
                  .dispatch("partnership/req_partnership_inquire_answer_list", {
                    partnership_inquire_post_id: this.itemdata.id,
                  })
                  .then((results) => {
                    // this.item.partnership_inquire_posts_answers = results.data;

                    this.answerDataList = [...results.data];
                    this.answerDataList.sort((a, b) => {
                      if (a.id > b.id) return -1;
                      if (a.id < b.id) return 1;
                      return 0;
                    });

                    console.log("list => ", results);
                  });
              })
              .catch((error) => {
                // this.$alert("수정하였습니다.").then(() => {
                //   this.$router.push({ name: "CustomerVoicePostList" });
                // });
                console.log("error => ", error);
              });
          } else {
            //
            console.log("cancel 답변등록");
          }
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    // 삭제기능은 개편수정(2022.09.05) 되면서 기능 disable 됨 )
    // onSubmitDelete() {
    //   console.log("onSubmitDelete");
    //   this.$store
    //     .dispatch("partnership/remove_partnership_post_list", {
    //       idarr: [this.item.id],
    //     })
    //     .then(() => {
    //       // 삭제 완료후 리스트 페이지로 이동.
    //       this.$router.push({ name: "PartnershipPostList" });
    //     });
    // },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/helpers/variables";
@import "@/assets/scss/helpers/mixins";
@import "@/assets/scss/base/typhography";

// 제휴문의
.partnership-post-show {
  article {
    margin-top: 100px;
    &:first-child {
      margin-top: 0;
    }
  }
  &__inquiry {
  }
  &__email {
  }
  &__history {
    table {
      .col1 {
        width: 100px;
      }
      .col3 {
        width: 250px;
      }
      td {
        text-align: center;
      }
    }
  }
  .manager {
    label {
      font-weight: 500;
    }
    span {
      color: $color-red;
    }
    input {
      width: 250px;
      height: 50px;
      border: 1px solid #ccc;
      margin: 0 10px 0 20px;
    }
  }
}
</style>
