<template>
  <div>
    <section class="partnership-post-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>제휴 유형 설정</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PRODUCTS</span>&nbsp;&gt;&nbsp;
            <span>제휴문의</span>
          </p>
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
            </colgroup>
            <thead>
              <tr>
                <th>No.</th>
                <th>제휴 유형</th>
                <th>순번</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in typeList" v-bind:key="item.id">
                <!-- <tr> -->
                <td>{{ item.id }}</td>
                <td>
                  <div class="flex">
                    <input
                      type="text"
                      :value="item.name"
                      @input="onChangeTypeName($event, item, index)"
                    />
                    <button
                      class="del-btn"
                      @click="onButtonDeleteTypeItem(item, index)"
                    >
                      <img
                        src="@/assets/image/btn-del.png"
                        alt="delete button"
                      />
                      삭제
                    </button>
                  </div>
                </td>

                <td class="order">
                  <button @click="onOrderChangeTop(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeUp(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeDown(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeBottom(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="space-between">
            <router-link
              :to="{ name: 'PartnershipPostList' }"
              class="btn-white"
              tag="a"
              >목록</router-link
            >
            <div class="btn-wrap">
              <button class="btn-white" @click="addTypeItem">유형추가</button>
              <button class="btn-blue" @click="onButtonSave">저장</button>
            </div>
          </div>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>
  
  <script>
import AppFooter from "../../AppFooter.vue";
import { mapGetters } from "vuex";

export default {
  components: { AppFooter },
  name: "PartnershipTypeCreate",
  data() {
    return {
      typeList: [],
      get TOP_INDEX() {
        return 0; //
      },
      orgTypeList: null,
    };
  },
  computed: {
    ...mapGetters("partnership", ["getPartnershipTypeList"]),
    BOTTOM_INDEX() {
      return this.typeList.length; //
    },
  },
  created() {
    this.loadListData();
  },
  methods: {
    loadListData() {
      this.$store
        .dispatch("partnership/req_partnership_type_list", {})
        .then(() => {
          this.typeList = [...this.getPartnershipTypeList];
          this.typeList.sort((a, b) => {
            return a.order - b.order;
          });

          this.orgTypeList = JSON.parse(
            JSON.stringify(Object.assign([], this.typeList))
          );
          console.log("orgTypeList => ", this.orgTypeList);
        });
    },
    // eslint-disable-next-line no-unused-vars
    onChangeTypeName(event, item, index) {
      var inputtext = event.target.value;
      inputtext = inputtext.replace(/^\s+|\s+$/g, "");
      inputtext = inputtext.replace(/\s+$/, "");
      // item.name = inputtext;
      this.typeList[index].name = inputtext;
      // console.log("text value", inputtext, "item => ", item, "index : ", index);
      console.log("this.typeList => ", this.typeList);
    },
    addTypeItem() {
      const maxid = this.getMaxTypeId() + 1;
      const lastOrder = this.getMaxTypeId() + 1;

      const typeItem = {
        id: maxid,
        name: "",
        order: lastOrder,
      };

      this.typeList.push(typeItem);
    },
    // eslint-disable-next-line no-unused-vars
    updateTypeListData(payload) {
      // eslint-disable-next-line no-unused-vars
      // this.$store.dispatch("education/req_update_department_order_list", payload ).then( result => {
      //   // console.log( 'req_update_department_order_list result => ', result );
      // }).catch( err => {
      //   console.log( ' error => ', err );
      // });
    },
    generagteTypeOrderingList() {
      var arrlist = [];
      // var offset = (this.current_page - 1 ) * this.pagesize + 1  ;
      var offset = 1;
      for (let [index, item] of this.typeList.entries()) {
        arrlist.push({
          id: item.id,
          order: offset + index,
          name: item.name,
        });
      }
      return arrlist;
    },
    onOrderChangeTop(item, index) {
      // console.log("onOrderChangeTop item ", item, "index => ", index);

      if (index > this.TOP_INDEX) {
        // 이동 가능.
        // data_list 으로 부터 1개 삭제함, splice의 return 값은 array이다.
        const slicedItemList = this.typeList.splice(index, 1);
        this.typeList.splice(this.TOP_INDEX, 0, slicedItemList[0]);

        this.typeList = this.generagteTypeOrderingList();
      } else {
        // 이동 불가. 이미 TOP
        // console.log("이미 최상위");
      }
    },
    onOrderChangeUp(item, index) {
      // console.log("onOrderChangeUp item ", item, "index => ", index);
      if (index > this.TOP_INDEX) {
        const slicedItemList = this.typeList.splice(index, 1);
        var upIndex = index - 1;
        this.typeList.splice(upIndex, 0, slicedItemList[0]);

        this.typeList = this.generagteTypeOrderingList();
      } else {
        // 이동 불가. 이미 TOP
        // console.log("이미 최상위");
      }
    },
    onOrderChangeDown(item, index) {
      console.log("onOrderChangeDown item ", item, "index => ", index);
      if (index < this.BOTTOM_INDEX - 1) {
        // 이동 가능.
        const slicedItemList = this.typeList.splice(index, 1);
        var downIndex = index + 1;
        this.typeList.splice(downIndex, 0, slicedItemList[0]);
        this.typeList = this.generagteTypeOrderingList();
      } else {
        // 이동 불가. 이미 TOP
        // console.log("이미 최하위");
      }
    },
    onOrderChangeBottom(item, index) {
      console.log("onOrderChangeBottom item ", item, "index => ", index);
      if (index < this.BOTTOM_INDEX) {
        // 이동 가능.
        // data_list 으로 부터 1개 삭제함, splice의 return 값은 array이다.
        const slicedItemList = this.typeList.splice(index, 1);
        this.typeList.splice(this.BOTTOM_INDEX, 0, slicedItemList[0]);
        this.typeList = this.generagteTypeOrderingList();
      } else {
        // 이동 불가. 이미 TOP
        // console.log("이미 최하위");
      }
    },
    getMaxTypeId() {
      const ids = this.typeList.map((object) => {
        return object.id;
      });
      const max = Math.max.apply(null, ids);
      return max;
    },
    getLastOrder() {
      const orders = this.typeList.map((object) => {
        return object.order;
      });
      const lastOrder = Math.max.apply(null, orders);
      return lastOrder;
    },
    onButtonSave() {
      // 저장 버튼 실행시
      // 추가 또는 수정된 경우 서버에 수정변경을 요청함

      // 삭제 list orgTypeList에만 있는 itemList는 삭제대상
      // 수정 list ( order 또는 유형이름 변경)  교집합 item들은 업데이트대상
      // 추가 list typeList에만 있는 item은 추가 대상.

      const originalTypeList = [...this.orgTypeList];
      const finalTypeList = [...this.typeList];
      console.log("orgTypeList => ", originalTypeList);
      console.log("modifed typeList=>  ", finalTypeList);

      // validation 체크
      // 이름에 빈이름이 있으면 안됨. 중복된 이름도 안됨.
      if (finalTypeList.find((item) => item.name == "") !== undefined) {
        // name 이 없는 item 이 있으면 안됨.
        return this.$fire({
          title: null,
          text: "제휴 유형의 이름이 없으면 저장할수 없습니다.",
          type: "warning",
          timer: 2000,
        }).then((r) => {
          console.log(r.value);
        });
      }

      // 이름이 중복된 아이템이 있는지 체크한다.
      const uniqueTypeNames = new Set(finalTypeList.map((v) => v.name));
      if (uniqueTypeNames.size < finalTypeList.length) {
        return this.$fire({
          title: null,
          text: "제휴 유형의 이름이 중복되면 저장할수 없습니다.",
          type: "warning",
          timer: 2000,
        }).then((r) => {
          console.log(r.value);
        });
      }

      //Find types that are in orgTypeList but not in typeList
      const delList = originalTypeList.filter(function (obj) {
        return !finalTypeList.some(function (obj2) {
          return obj.id == obj2.id;
        });
      });

      //Find types that are in typeList but not in orgTypeList
      const addList = finalTypeList.filter(function (obj) {
        return !originalTypeList.some(function (obj2) {
          return obj.id == obj2.id;
        });
      });

      // 최종 리스트에서 추가 아이템을 제외한 아이템 리스트를 뽑음.
      const modList = finalTypeList.filter((object1) => {
        return !addList.some((object2) => {
          return object1.id === object2.id;
        });
      });

      const realModList = modList.filter((object1) => {
        return !originalTypeList.some((object2) => {
          return (
            object1.id === object2.id &&
            object1.name === object2.name &&
            object1.order === object2.order
          );
        });
      });
      console.log("realModList => ", realModList);
      const payload = {
        delList: delList,
        addList: addList,
        modList: realModList,
      };

      // console.log("payload => ", payload);

      if (
        payload.delList.length +
          payload.addList.length +
          payload.modList.length >
        0
      ) {
        this.$store
          .dispatch("partnership/req_update_partnership_type_list", payload)
          .then((result) => {
            console.log("save result => ", result);
            this.loadListData();

            this.$fire({
              title: "저장하였습니다.",
              text: "",
              type: "success",
              timer: 2000,
            }).then(() => {
              console.log("저장 성공");
            });
          });
      } else {
        // 변화가 없으므로 서버에 저장 요청 안함.
        console.log("저장할 내용이 없음.");
      }
    },
    onButtonDeleteTypeItem(item, index) {
      console.log("onButtonDeleteTypeItem item ", item, "index => ", index);

      // 먼저 삭제 하고자 하는 아이템이 삭제가 가능한 아이템인지 서버에 조회하여 확인하여야한다.
      // 삭제가 불가능한경우 삭제하지 않는다.
      // 삭제가 가능한경우에만 어레이에서 제거한다.
      const payload = {
        type_id: item.id,
      };
      this.$store
        .dispatch("partnership/req_partnership_type_contents_count", payload)
        .then((result) => {
          console.log("count => ", result.data.count);
          let count = result.data.count;
          if (count > 0) {
            // 삭제 불가능
            return this.$fire({
              title: "삭제 불가능!",
              text: "제휴 문의글이 있는 유형은 삭제할 수 없습니다.",
              type: "warning",
              timer: 3000,
            }).then((r) => {
              console.log(r.value);
            });
          } else {
            // 삭제가 가능하므로 어레이에서 제거
            this.typeList = this.typeList.filter((object) => {
              return object.id !== item.id;
            });
            this.typeList = this.generagteTypeOrderingList();
          }
        })
        .catch((error) => {
          if (error.response.status == 404) {
            // 서버에 저장 안된 경우임 그냥 list에서 제거하면됨.
            this.typeList = this.typeList.filter((object) => {
              return object.id !== item.id;
            });
            this.typeList = this.generagteTypeOrderingList();
          }
        });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.partnership-post-create {
  .section-inner .section-cont table {
    .col1 {
      width: 60px;
    }
    td {
      &:nth-child(1) {
        text-align: center;
      }
      padding: 10px 15px;
      input[type="text"] {
        width: 80%;
        height: 45px;
        display: inline-block;
      }
    }
  }
}

.del-btn {
  display: inline-flex;
  align-items: center;
  height: 45px;
  margin-left: 8px;
}
.btn-wrap {
  > * {
    margin-left: 10px;
  }
}
</style>
  