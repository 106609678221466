<template>
  <div>
    <section class="partnership-post-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>제휴 유형 설정</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PRODUCTS</span>&nbsp;&gt;&nbsp;
            <span>제휴문의</span>
          </p>
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
            </colgroup>
            <thead>
              <tr>
                <th>No.</th>
                <th>제휴 유형</th>
                <th>순번</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td><input type="text" value="마케팅 제휴" /></td>
                <td class="order">
                  <button @click="onOrderChangeTop(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeUp(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeDown(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeBottom(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td><input type="text" value="안경테 제휴" /></td>
                <td class="order">
                  <button @click="onOrderChangeTop(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeUp(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeDown(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeBottom(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td><input type="text" value="안경렌즈 제휴" /></td>
                <td class="order">
                  <button @click="onOrderChangeTop(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeUp(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeDown(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeBottom(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td><input type="text" value="콘택트렌즈 제휴" /></td>
                <td class="order">
                  <button @click="onOrderChangeTop(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeUp(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeDown(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeBottom(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td><input type="text" value="상품권 제휴" /></td>
                <td class="order">
                  <button @click="onOrderChangeTop(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeUp(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeDown(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeBottom(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td><input type="text" value="입점 제안" /></td>
                <td class="order">
                  <button @click="onOrderChangeTop(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeUp(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeDown(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeBottom(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td><input type="text" value="시스템 제휴" /></td>
                <td class="order">
                  <button @click="onOrderChangeTop(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeUp(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeDown(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeBottom(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>
                  <div class="flex">
                    <input type="text" />
                    <button class="del-btn">
                      <img
                        src="@/assets/image/btn-del.png"
                        alt="delete button"
                      />
                      삭제
                    </button>
                  </div>
                </td>
                <td class="order">
                  <button @click="onOrderChangeTop(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeUp(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeDown(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-top.png"
                      alt=""
                    />
                  </button>
                  <button @click="onOrderChangeBottom(item, index)">
                    <img
                      src="../../../assets/image/icon-caret-double-top.png"
                      alt=""
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="space-between">
            <router-link
              :to="{ name: 'PartnershipPostList' }"
              class="btn-white"
              tag="a"
              >목록</router-link
            >
            <div class="btn-wrap">
              <button class="btn-white">유형추가</button>
              <button class="btn-blue">저장</button>
            </div>
          </div>
          <!-- /.btn -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

//
//
//  Do not use this file for 제휴 유영 관련해서.........
//  use PartnershipTypeCreate.vue
//
//

export default {
  components: { AppFooter },
  name: "PartnershipPostCreate",
};
</script>

<style lang="scss" scoped>
.partnership-post-create {
  .section-inner .section-cont table {
    .col1 {
      width: 60px;
    }
    td {
      &:nth-child(1) {
        text-align: center;
      }
      padding: 10px 15px;
      input[type="text"] {
        width: 80%;
        height: 45px;
        display: inline-block;
      }
    }
  }
}

.del-btn {
  display: inline-flex;
  align-items: center;
  height: 45px;
  margin-left: 8px;
}
.btn-wrap {
  > * {
    margin-left: 10px;
  }
}
</style>
