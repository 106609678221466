<template>
  <div>
    <section class="store-open-inquire-post-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>가맹점 개설문의</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PARTNERSHIP</span>&nbsp;&gt;&nbsp;
            <span>가맹점 개설문의</span>
          </p>
          <p class="total-post">
            전체 게시물 <span>{{ getDataTotalCount }}</span
            >개
          </p>
          <!-- /.total-post -->
          <search-input :searchHandler="onSearch" />
          <!-- /.search -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
              <col class="col4" />
              <col class="col5" />
              <col class="col6" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col" class="check-cell">
                  <label for="" class="hidden">전체체크</label
                  ><input
                    type="checkbox"
                    @click="check()"
                    v-model="checkAllValue"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="../../../assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </th>
                <th scope="col">No.</th>
                <th scope="col">희망지역</th>
                <th scope="col">성함</th>
                <th scope="col">연락처</th>
                <th scope="col">등록일</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getData" v-bind:key="item.id">
                <td class="check-cell">
                  <label for="" class="hidden">개설문의체크</label
                  ><input
                    type="checkbox"
                    v-model="checkedValues"
                    :value="item.id"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="../../../assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'StoreOpenInquirePostShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ item.id }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'StoreOpenInquirePostShow',
                      params: { id: item.id, item: item },
                    }"
                    >{{ item.hope_area_name }}</router-link
                  >
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'StoreOpenInquirePostShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ maskingFuncName(item.name) }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'StoreOpenInquirePostShow',
                      params: { id: item.id, item: item },
                    }"
                    >{{ maskingFuncPhone(item.mobile_no) }}
                  </router-link>
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'StoreOpenInquirePostShow',
                      params: { id: item.id, item: item },
                    }"
                  >
                    {{ $moment(item.created_dttm).format("YYYY.MM.DD") }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div>
          <!--./paginate-->
          <div class="btn-icon-del-wrap">
            <button class="btn-icon-del" @click="onConfirmDelete">
              <img src="../../../assets/image/btn-del.png" alt="delete icon" />
              <span>선택삭제</span>
            </button>
          </div>
          <!-- /.btn-wrap -->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import Paginate from "../../Paginate.vue";
import { mapGetters } from "vuex";
import SearchInput from "../../SearchInput.vue";

export default {
  components: { AppFooter, Paginate, SearchInput },
  name: "StoreOpenInquirePostList",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getData: "partnership/getStoreOpenInquirePostList",
      getDataTotalCount: "partnership/getStoreOpenInquirePostTotalCount",
    }),
  },
  created() {
    console.log("StoreOpenInquirePostList created ====> ");
    this.$store.dispatch("partnership/req_store_open_inquire_post_list", {
      page: 1,
      size: this.PAGE_SIZE,
    });
  },
  methods: {
    handlePageSelected(page) {
      console.log("handlePageSelected => ", page);
      this.$store.dispatch("partnership/req_store_open_inquire_post_list", {
        page: page,
        size: this.PAGE_SIZE,
      });
    },
    onSearch(text) {
      this.$store.dispatch("partnership/req_store_open_inquire_post_list", {
        page: 1,
        size: this.PAGE_SIZE,
        searchtext: text,
      });
    },
    onSubmitDelete() {
      console.log("onSubmitDelete ==> ", this.checkedValues);
      if (this.checkedValues.length > 0) {
        // choice
        this.$store
          .dispatch("partnership/remove_store_open_inquire_post_list", {
            idarr: this.checkedValues,
          })
          .then(() => {
            this.checkedValues = [];
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.store-open-inquire-post-list {
  .section-inner {
    .section-cont {
      table {
        colgroup {
          .col1 {
            width: 64px;
          }
        }
      }
      .btn-icon-del-wrap {
        display: flex;
        justify-content: flex-end;
        .btn-icon-del {
        }
      }
    }
  }
}
</style>
