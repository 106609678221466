<template>
  <div>
    <section class="store-open-inquire-post-show ly-show">
      <div class="section-inner">
        <div class="section-cont">
          <h2>가맹점 개설문의</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PARTNERSHIP</span>&nbsp;&gt;&nbsp;
            <span>가맹점 개설문의</span>
          </p>
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">희망지역</th>
              <td>{{ this.item.hope_area_name }}</td>
            </tr>
            <tr>
              <th scope="row">안경사 경력</th>
              <td>{{ careerYears }}</td>
            </tr>
            <tr>
              <th scope="row">가용가능 금액</th>
              <td>{{ commaMoney }}</td>
            </tr>
            <tr>
              <th scope="row">성함</th>
              <td>{{ this.item.name }}</td>
            </tr>
            <tr>
              <th scope="row">연락처</th>
              <td>{{ this.item.mobile_no }}</td>
            </tr>
            <tr>
              <th scope="row">이메일</th>
              <td>{{ this.item.email }}</td>
            </tr>
            <tr>
              <th scope="row">개설 희망 이유</th>
              <td>
                <p v-html="strReplaceReturnText(this.item.open_reasons)" />
              </td>
            </tr>
          </table>
          <!--/.table-->
          <router-link
            :to="{ name: 'StoreOpenInquirePostList' }"
            class="btn-white"
            >목록</router-link
          >
          <button
            class="btn-icon-del btn-icon-del-s btn-right"
            @click="onConfirmDelete"
          >
            <img src="../../../assets/image/btn-del.png" alt="delete icon" />
            <span>삭제</span>
          </button>
          <!--/.btn-->
        </div>

        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";

export default {
  props: ["id", "item"],
  components: { AppFooter },
  name: "StoreOpenInquirePostShow",
  created() {
    console.log("StoreOpenInquirePostShow created ====> ");
    console.log(
      "StoreOpenInquirePostShow created ====> ",
      this.id,
      " item => ",
      this.item
    );
  },
  computed: {
    careerYears() {
      return this.item.experienced_years + " 년차";
    },
    commaMoney() {
      return this.numberWithCommas(this.item.investable_money) + " 원";
    },
  },
  methods: {
    strReplaceReturnText(text) {
      let replacedText = text.replace(/(?:\r\n|\r|\n)/g, "<br />");
      return replacedText;
    },
    onSubmitDelete() {
      console.log("onSubmitDelete ");

      this.$store
        .dispatch("partnership/remove_store_open_inquire_post_list", {
          idarr: [this.item.id],
        })
        .then(() => {
          // 삭제 완료후 리스트 페이지로 이동.
          this.$router.push({ name: "StoreOpenInquirePostList" });
        });
    },
  },
};
</script>
