<template>
  <div>
    <section class="store-open-area-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>가맹점 오픈가능지역 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp;
            <span>PARTNERSHIP</span>&nbsp;&gt;&nbsp;
            <span>가맹점 오픈가능지역 관리</span>
          </p>
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="store-division1">대분류</label>
              </th>
              <td> 
                <select name="store-division1" id="store-division1"  @change="onChangeDivision1()" v-model="division1">
                  <option value="선택">선택</option>
                  <option value="서울">서울</option>
                  <option value="영남">영남</option>
                  <option value="경기">경기</option>
                  <option value="그 외">그 외</option>
                </select>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="store-division2">중분류</label>
              </th>
              <td>
                <select name="store-division2" id="store-division2" @change="onChangeDivision2()" v-model="division2">
                  <option
                    v-for="item in this.division2List"
                    v-bind:key="item.id"
                    :value="item"
                  >
                    {{ item.name }} 
                  </option>

                </select>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="store-division3">소분류 등록</label>
              </th>
              <td class="register-cell">
                <input
                  type="text"
                  id="store-division3"
                  name="store-division3"
                  v-model="inputDetails"
                  placeholder="추가입력"
                />
                <input type="submit" @click="onSubmitDetails" />
              </td>
            </tr>
            <tr>
              <th>소분류</th>
              <td class="store-division3-wrap">
                <p class="store-division3-empty" v-show="arealist.length == 0">등록된 분류가 없습니다.</p>
                <p
                  v-for="item in this.arealist"
                  v-bind:key="item.name"
                  class="store-division3-cont"
                >
                  {{ item.name  }}
                  <button @click="onClickRemoveItem(item)">
                    <img
                      src="../../../assets/image/btn-del-b.png"
                      alt="search icon"
                    />
                  </button>
                  <span></span>
                </p>

              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link :to="{ name: 'StoreOpenAreaList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onCreateRequest">저장</button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
// import { mapGetters } from "vuex";

export default {
  components: { AppFooter },
  name: "StoreOpenAreaCreate",
  data() {
    return {
      division1: "서울",
      division2: null ,
      division2List: [], 
      inputDetails: "",
      arealist: [ ],
      
    }
  },
  computed: {

    // ...mapGetters({
    //   getStoreAreaData: "partnership/getStoreAreaCodeList",
    // }),
    // getDivision1: function() {
    //   var areadata = this.getStoreAreaData ; 
    //   areadata = areadata.filter( el => el.class_type == 1); 
    //   return areadata; 
    // }
    getAreaCodeList: function() {
      return this.$store.getters['partnership/getStoreAreaCodeList']; 
    },
    getDivision1: function() {
      var codelist = this.$store.getters['partnership/getStoreAreaCodeDivision1List']
      return codelist; 
    },
    

  },
  created() {
    console.log('StoreOpenAreaCreate ==> ', ); 
    this.$store.dispatch("partnership/req_store_area_code_list").then( () => {
      var codelist = this.$store.getters['partnership/getStoreAreaCodeList']
      console.log( 'codelist => ',codelist ); 
      this.division1 = "서울"; 
      this.onChangeDivision1();
    }) ; 


  },
  methods: {
    onChangeDivision1() {
      console.log('onChangeDivision1 ==> ', this.division1 );
      // var division1list = this.getDivision1 ;
      let division_code ; 
      if( "서울" == this.division1 )      { division_code = 1 ;  }
      else if( "영남" == this.division1 ) { division_code = 2 ;  }
      else if( "경기" == this.division1 ) { division_code = 3 ;  }
      else if( "그외" == this.division1.replace(/ /gi, "") ) {division_code = 4 ; } 

      console.log( ' this.getAreaCodeList ', this.getAreaCodeList , division_code  ); 
      this.division2List = this.getAreaCodeList.filter( el => el.parent_id == division_code && el.class_type == 2 ) ; 
      console.log( ' this.division2 ==>  ',this.division2List ); 
      this.division2 = this.division2List[0]; 

    },
    onChangeDivision2() {
      console.log('onChangeDivision2 ==> ', this.division2);
      

    },
    onSubmitDetails() {
      console.log("onSubmitDetails ==>> ");
      if (this.inputDetails.length > 0) {
        this.arealist = this.arealist.filter(item => item.name != this.inputDetails)
        this.arealist.push( { name: this.inputDetails, area_id : this.division2.id } );
        this.inputDetails = "";
      }
      console.log("onSubmitDetails ==>> ");
    },
    onClickRemoveItem(item) {
      console.log("onRemoveItem =>", item);
      this.arealist = this.arealist.filter((element) => element != item);
    },
    onCreateRequest() {
      console.log("onCreateRequest =>"); 

      if( this.arealist.length > 0 ) {
        console.log( 'arealist => ', this.arealist ); 
        // data 있음. 

        // 등록 요청 request 
        this.$store.dispatch("partnership/req_create_store_area_detailslist" , { detailslist: this.arealist }).then( () => {
          this.$fire({
            title: this.msg.create_success_title,
            text: "", 
            type: "success",
            timer: 2000
          }).then(r => {
            console.log(r.value);
            this.$router.push({ name: "StoreOpenAreaList" });
          });
        })

      }
    }
  }
};


</script>



<style lang="scss">
@import "/src/assets/scss/helpers/variables";
@import "/src/assets/scss/helpers/mixins";
@import "/src/assets/scss/base/typhography";

.store-open-area-create,
.store-open-area-edit {
  .register-cell {
    display: flex;
    input[name="store-division3"] {
      width: 150px !important;
      display: inline-block;
      &::placeholder {
        color: #ccc;
      }
    }
    input[type="submit"] {
      display: inline-block;
      width: 110px;
      height: 50px;
      border: 1px solid $color-black;
      &::placeholder {
        color: #ccc;
      }
    }
  }
  .store-division3-empty {
    color: #ccc;
  }
  .store-division3-wrap {
    display: flex;
    .store-division3-cont {
      display: inline-block;
      padding: 2px 5px 2px 20px;
      border: 1px solid #dfdfdf;
      border-radius: 25px;
      margin-right: 10px;
      @include box-center-flex;
      button {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin-left: 4px;
        @include box-center-flex;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>
